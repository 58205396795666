<template>
  <div>
    <div class="wechat-pc-pay">
      <div class="wechat-pc-box">
        <div class="wechat-pc-header">
          <!-- <img src="../../../assets/image/lexinTlogo.png" alt="" /> -->
        </div>
        <div class="wechat-pc-title">
          微信扫一扫,进行付款
        </div>
        <div class="wechat-pc-qrcUrl">
          <img :src="qrc.qrcUrl" alt="二维码扫码" />
        </div>
        <div class="wechat-pc-order">
          <span>订单编号:</span>
          <span>{{ qrc.userOrderId ? qrc.userOrderId : '无' }}</span>
        </div>
        <div class="wechat-pc-role">
          <span>角色名称:</span>
          <span>{{ qrc.userName ? qrc.userName : '无' }}</span>
        </div>
        <div class="wechat-pc-amount">
          ￥{{ qrc.amount ? qrc.amount : '0' }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      qrc: {
        userName: '',
        amount: 0,
        userOrderId: '',
        qrcUrl: '',
      },
    };
  },
  created() {
    let { userName, amount, userOrderId, qrcUrl } = this.$route.query;
    this.qrc.userName = userName;
    this.qrc.amount = amount;
    this.qrc.userOrderId = userOrderId;
    this.qrc.qrcUrl = qrcUrl;
  },
};
</script>
<style lang="less" scoped>
.wechat-pc-pay {
  height: 100vh;
  background-image: url('../../../assets/image/blogin-bg.jpg');
  background-position: 50% 50%;
  background-size: cover;
  .wechat-pc-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 645px;
    height: 692px;
    background: #fff;
    box-shadow: 0px 2px 16px 0px rgba(12, 28, 65, 0.2);
    border-radius: 11px;
    .wechat-pc-header {
      padding: 15px;
    }
    .wechat-pc-title {
      font-size: 24px;
    }
    .wechat-pc-qrcUrl {
      width: 400px;
      height: 350px;
      margin: 20px auto;
      & img {
        width: 100%;
        height: 100%;
      }
    }
    .wechat-pc-order {
      margin: 10px auto;
      font-size: 18px;
      width: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .wechat-pc-role {
      margin: 10px auto;
      font-size: 18px;
      width: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .wechat-pc-amount {
      font-size: 28px;
      font-weight: 700;
    }
  }
}
</style>
